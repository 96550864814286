import React from "react";
import Container from "../atoms/Container";
import styled from "styled-components";
import Flag from '../../assets/svg/flag.inline.svg';
import DoubleDot from '../../assets/svg/doubleDot.inline.svg';
import { useStaticQuery, graphql } from 'gatsby';
import MarkdownConverter from "../atoms/MarkdownConverter";

const Holder = styled.footer`
  border-top: 1px solid;
  margin-bottom: 4rem;
`;
const Inner = styled.div`
  a:hover { text-decoration: underline; }
  .cta {
    margin: 4rem 0;
  }
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  @media( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 3rem;
  }
  p, h3 {
    ${(props) => props.theme.fluidType(-1)};
    margin: 0.5rem 0;
  }
  h3 {
    @media( ${props => props.theme.breakpoints.md} ) {
      margin-bottom: 1.5rem;
    }
    @media( ${props => props.theme.breakpoints.lg} ) {
      margin-bottom: 0.5rem;
    }
  }

  > div {
    > :first-child { margin-top: 0; }

    > :last-child { margin-bottom: 0; }
  }

  .aoc {
    @media( ${props => props.theme.breakpoints.md} ) {
      grid-column: span 12;
    }
    @media( ${props => props.theme.breakpoints.lg} ) {
      grid-column: span 5;
    }
    svg {
      width: 2rem;
      height: auto;
      display: block;
      margin-bottom: 1rem;
    }
  }

  .contact {
    @media( ${props => props.theme.breakpoints.md} ) {
      grid-column: span 5;
    }
    @media( ${props => props.theme.breakpoints.lg} ) {
      grid-column: 7/10;
    }
    .instagram {
      margin-top: 1.5rem;
    }
  }
  
  .address-holder {
    @media( ${props => props.theme.breakpoints.md} ) {
      grid-column: span 7;
    }
    @media( ${props => props.theme.breakpoints.lg} ) {
      grid-column: span 3;
    }
    .address {
      display: grid;
      grid-template-columns: 1fr 1rem;
      margin-top: 1rem;
      @media( ${props => props.theme.breakpoints.lg} ) {
        margin-top: 0.5rem;
        grid-template-columns: 1fr 0.5rem;
      }
      p {
        margin: 0;
      }
      svg {
        width: 100%;
        height: auto;
      }
    }
  }
`;

function Footer() {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      contentfulGlobalSettings {
          aoc {
            aoc
          }
          address {
            address
          }
          email
          instagram
       }
    }
  `)
  const {email, instagram, aoc, address} = data.contentfulGlobalSettings;
  return (
    <Holder>
      <Container>
        <Inner>
          <p className="h3 cta">Want to work together? <a href={`mailto:${email}`} target="_blank"
                                                          rel="noopener noreferrer">Send us an email :)</a></p>
          <Grid>
            <div className="aoc">
              <Flag />
              <p>{aoc.aoc}</p>
            </div>
            <div className="contact">
              <h3>Contact</h3>
              <p><a href={`mailto:${email}`}>{email}</a></p>
              <p className="instagram"><a href={instagram}>Instagram</a></p>
            </div>
            <div className="address-holder">
              <h3>Sydney</h3>
              <div className="address">
                <MarkdownConverter content={address.address}/>
                <DoubleDot />
              </div>
            </div>
          </Grid>
        </Inner>
      </Container>
    </Holder>
  )
}

export default Footer
