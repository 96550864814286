import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {gsap} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Holder = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colours.white};
  background-color: ${props => props.theme.colours.black};

  p {
    ${(props) => props.theme.fluidType(6)};
    line-height: 1;
    margin: 0;
    @media ( ${props => props.theme.breakpoints.md} ) {
      ${(props) => props.theme.fluidType(7)};
    }
  }
`;

function Hero() {
  const holder = useRef(null);
  const tl = useRef(null);

  useEffect(() => {
    if (!tl.current) {
      tl.current = ScrollTrigger.matchMedia({
        // desktop
        "(min-width: 576px)": function () {
          gsap
            .timeline({
              scrollTrigger: {
                trigger: holder.current,
                start: "bottom bottom",
                end: "bottom top",
                scrub: 0.5,
              },
            })
            .to(`#hero-text`, {
              yPercent: 150,
              duration: 1,
            })
        },
        "(max-width: 575px)": function () {
          gsap
            .timeline({
              scrollTrigger: {
                trigger: holder.current,
                start: "bottom bottom",
                end: "bottom top",
                scrub: 1,
              },
            })
            .to(`#hero-text`, {
              yPercent: 300,
              duration: 1,
            })
        },
      })
    }
  },)

  return (
    <Holder ref={holder}>
      <p id="hero-text">workhouse</p>
    </Holder>
  )
}

export default Hero;