import {graphql, Link, useStaticQuery} from "gatsby";
import React from "react";
import Container from "../atoms/Container";
import styled from "styled-components";

const Holder = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  color: ${props => props.theme.colours.black};
  padding: 1rem 0;
  transition: all 0.25s ease-in-out;

  &.active {
    color: ${props => props.theme.colours.white};
  }
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 5;

  h1, p {
    margin: 0;
  }
`;

function Header() {

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      contentfulGlobalSettings {
          email
       }
    }
  `)

  return (
    <Holder id="header">
      <Container>
        <Inner>
          <h1 className="p"><Link to="/">workhouse</Link></h1>
          <p><a href={`mailto:${data.contentfulGlobalSettings.email}`}>Contact</a></p>
        </Inner>
      </Container>
    </Holder>
  );
}

export default Header;
